<template>
  <div>
    <div class="header">
      <a href="/hallucinations-fix" class="home">
        Центр AI-маркетинга :: Сервис исправления галлюцинаций LLM
      </a>

      <a href="/hallucination-fix-history" class="home">
        :: История запросов
      </a>

    </div>

    <div class="mainsmm">


      <div class="row">
        <h1 class="title titleBold">Тезисы, идеи, факты для генерации текста (бриф)</h1>
        <textarea ref="textarea" class="form-control prompt__textarea mt-2" placeholder="Введите бриф:"
          v-model="input_sql.input_text.brief" />
      </div>

      <div class="row">
        <h1 class="title titleBold">Текст LLM</h1>
        <textarea ref="textarea" class="form-control prompt__textarea mt-2" placeholder="Введите текст от LLM:"
          v-model="input_sql.input_text.generation" />
      </div>


      <div class="flex-container flex-start">
        <button class="prompt__btn prompt__btn-submit" @click="startChainOfVerification()">
          Исправить
        </button>
      </div>


    </div>
  </div>
</template>

<script>


let sseClient;

import store from "@/store/store";

import {
  SquareIcon,
  DownloadIcon,
  CopyIcon,
  RefreshCwIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

export default {
  components: {
    SquareIcon,
    DownloadIcon,
    CopyIcon,
    RefreshCwIcon,
    HelpCircleIcon,
  },

  name: "SMM",

  data() {
    return {
      input_parameters: {
        temperature: 50,
        withImage: false,
        styleImage: "no_style",
        styleText: "",
        prompt: "",
        image_prompt: "",
      },
      styleImageArr: [],
      styleTextArr: [],
      article_strs: {
        0: "",
        1: "",
        2: "",
        3: "",
      },
      stopFlags: {
        0: true,
        1: true,
        2: true,
        3: true,
      },
      prompt: [],
      hash: "",
      prompt_str: "",
      image_prompt_str: "",

      input_sql: {
        hash: '', user_id: 0, input_text: { brief: '', generation: '' }
      },
      statusProcess: 0,
    };
  },

  async created() {
    this.hash = this.$CryptoJS.MD5("Hi There!" + new Date()).toString();
    console.log("hash = " + this.hash);
    this.input_sql.hash = this.hash;
    this.input_sql.user_id = store.state.user_id;
    document
      .getElementById("theme-opt")
      .setAttribute("href", "/css/style-null.css");
    document
      .getElementById("color-opt")
      .setAttribute("href", "/css/colors/default-null.css");



  },
  mounted() {


    sseClient = this.$sse.create({
      url: '' + this.$hostnamejava + '/flowise/stream-sse/' + this.hash ,
      format: 'plain',
      withCredentials: false,
      polyfill: true,
    });

    sseClient.on('message', this.handleMessage);

    sseClient.connect()
      .then(sse => {
        console.log('We\'re connected!');
      })
      .catch((err) => {
        console.error('Failed to connect to server', err);
      });

  },
  beforeDestroy() {

    sseClient.disconnect();

  },

  methods: {

    handleMessage(message, id) {
      console.log(" message: " + message + ' ' + ' ' + id)
      var jo = JSON.parse(message);
      this.statusProcess = Math.floor(jo.num_job_done * 100 / jo.num_job);
      if (this.statusProcess === 100) {
        window.open(this.$hostnamefront + '/smm-history')
      }
    },



    startChainOfVerification() {

      console.log(this.input_sql);

      this.axios({
        method: "post",
        url: this.$hostnamejava + "/flowise/execute-chain-of-verification",
        data: this.input_sql,
      }).then(function (response) {
        //let data = response.data["data"]

      });
    },
  },
};
</script>

<style scoped>
/* Offset between elements */
* {
  --container-width: 1366px;
  --offset-xxs: 4px;
  --offset-xs: 8px;
  --offset-sm: 12px;
  --offset-md: 16px;
  --offset-lg: 20px;
  --offset-xl: 24px;
  --offset-xxl: 28px;
  --offset-huge: 32px;
  --offset-jumbo: 40px;
  --color-light-gray: #97a0af;
  --color-light-bg-outlet: #e6e7f0;
  --color-light-bg-dark: #ebecf2;
  --color-light-black: #232628;
  --color-light-red: #ff8f73;
  --color-light-yellow: #ffe380;
  --color-light-divider-light: var(--color-text-light-gray);
  --color-light-bg-light: #f4f5f7;
  --color-light-bg-accent: #d3d8e7;
  --color-light-bg-accent-card: #ffffff51;
  --color-light-text-gray: #6b778c;
  --color-light-bg-gray: var(--color-light-bg-light);
  --size-xxs: 4px;
  --size-xs: 8px;
  --size-sm: 12px;
  --size-md: 16px;
  --size-lg: 20px;
  --size-xl: 24px;
  --size-xxl: 28px;
  --size-huge: 32px;
  --size-jumbo: 40px;
  --font-fallback: "Verdana", "Roboto", sans-serif;
  --font-base: "SB Sans Text", var(--font-fallback);
  --font-caps: "SB Sans UI Caps", var(--font-base);
  --font-12: 400 12px / 14px var(--font-base);
  --font-14: 400 14px / 18px var(--font-base);
  --font-14-medium: 500 14px / 18px var(--font-base);
  --font-14-semibold: 600 14px / 18px var(--font-base);
  --font-16: 400 16px / 20px var(--font-base);
  --font-16-caps: 400 16px / 20px var(--font-caps);
  --font-16-bold: 700 16px / 20px var(--font-base);
  --font-18: 400 18px / 23px var(--font-base);
  --font-18-medium: 600 18px / 23px var(--font-base);
  --font-20: 400 20px / 25px var(--font-base);
  --font-20-medium: 500 20px / 25px var(--font-base);
  --font-24-medium: 500 24px / 30px var(--font-base);
  --font-20-semibold: 600 20px / 25px var(--font-base);
  --font-28-medium: 500 28px / 35px var(--font-base);
  --font-28-bold: 600 28px / 35px var(--font-base);
  --font-32-semibold: 600 32px / 40px var(--font-base);
  /* Caps UI */
  --font-12-caps: 400 12px / 14px var(--font-caps);
  --font-20-caps: 400 20px / 28px var(--font-caps);
  --color-text-black: var(--color-light-black);
  --color-text-light-red: #ffd7de;
  --color-text-light-gray: #dfe1e6;
  --color-text-very-light-gray: #b1bac9;
  --color-text-very-dark-gray: #313845;
  --color-text-gray: var(--color-light-text-gray);
  --color-text-dark-white: #d9dde3;
  --color-text-light-yellow: #fff1ca;
  --color-primary: #7b56e2;
  --color-primary-dark: #7351d4;
  --color-interactive: #563599;
  --color-gray: #5c5c5c;
  --color-black: #2c2c2c;
  --color-gray-dark: #dadde3;
  --color-white: #fff;
  --color-red: #f00;
  --color-error: #f57a87;
  --color-success: #8dd9be;
  --color-danger: #ff5630;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: calc(var(--size-jumbo) + var(--size-xs));
  background-color: var(--color-black);
  padding: 0 var(--offset-jumbo);
}

.home {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--offset-sm) 0;
  border: 0;
  border-radius: 0;
  font: var(--font-16-bold);
  color: var(--color-text-dark-white);
  text-decoration: none;
}

body {
  margin: 0;
}

.options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.link {
  position: relative;
  padding: var(--offset-sm) var(--offset-xl);
  height: 100%;
  border: none;
  border-radius: 0;
  text-decoration: none;
  font: var(--font-14-medium);
  color: var(--color-text-dark-white);
}

.speakers {
  display: flex;
  gap: 8px;
  align-items: center;
}

.speakers svg {
  height: var(--size-xs);
  transform: rotate(90deg);
  transition: rotate 0.25s ease-in-out;
  width: var(--size-xs);
}

.link:has(+ a)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: calc(100% - var(--offset-sm) * 2);
  background-color: var(--color-light-gray);
}

:where(button, a)[aria-disabled="true"],
button:disabled,
:global(.ant-btn-link:disabled) {
  pointer-events: none;
  background-color: transparent;
  color: var(--color-gray);

  &:global(.ant-btn:hover) {
    background-color: transparent;
    color: var(--color-gray);
  }
}

div.search {
  padding: 0;
  color: var(--color-text-dark-white);
  width: var(--size-lg);
  height: var(--size-lg);
  border: 0;
}

.link {
  display: flex;
  text-decoration: none;
  color: #d9dde3;
}

.link:hover {
  color: #b1b5c5;
}

.textMedium {
  font: 500 14px / 18px "SB Sans Text", "Verdana", "Roboto", sans-serif;
}

.titleBold {
  font: 600 32px / 40px "SB Sans Text", "Verdana", "Roboto", sans-serif;
}

.mainsmm {
  min-width: 390px;
  padding-top: 32px;
  padding-left: 40px;
  padding-right: 40px;
}

.title {
  margin: 0;
  padding: 0;
}

ul {
  margin-left: 24px;
  margin-top: 12px;
  line-height: 1.5rem;
}

h5 {
  /* width: 1000px; */
  white-space: pre-wrap;
  line-height: 1.2em;
  font-size: 1.2em;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Custom ui styles */

.prompt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
}

.prompt__textarea {
  margin-bottom: 32px;
  resize: none;
  border-width: 2px;
  border-color: transparent;
  box-shadow: 0 0 0 1px #ebecf2;
  padding: 12px 18px !important;
}

.prompt__textarea:focus {
  border-color: #7b56e2;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 0 rgba(42, 5, 130, 0.08);
}

.generation__textarea {
  margin-bottom: 32px;
  resize: none;
  border-width: 2px;
  border-color: transparent;
  box-shadow: 0 0 0 1px #ebecf2;
  padding: 12px 18px !important;
  border-radius: 0.25rem;
}

.generation__textarea:hover {
  border-color: #7b56e2;
}

.model__title {
  color: #495057;
}

.prompt__btn {
  font-weight: 500;
  margin: 0;
  outline: none;
  border: 0;
  cursor: pointer;
  padding: 11px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7b56e2;
  color: #fff;
  margin: 5px;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 0 rgba(42, 5, 130, 0.08);
}

.prompt__btn-stop {
  position: relative;
  padding-left: 28px;
  color: #232628;
  background-color: #f4f5f7;
  border: 1px solid #f4f5f7;
}

.prompt__btn-stop::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-color: #232628;
  z-index: 1;
  opacity: 0.25;
  transition: 0.3s ease-in-out;
}

.prompt__btn-stop:active {
  color: #493a6a;
  border-color: #493a6a;
}

.prompt__btn-stop:hover,
.prompt__btn-stop:focus-visible {
  color: #7b56e2;
  border-color: #7b56e2;
}

.prompt__btn-stop:hover::before,
.prompt__btn-stop:focus-visible::before {
  background-color: #7b56e2;
  opacity: 0.9;
}

.prompt__btn-submit:active {
  background-color: #493a6a;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}

.fb-chat {
  width: 100px;
  line-height: 60px;
  display: block;
  border-radius: 30% / 50%;
  position: fixed;
  left: 50%;
  /*top: 50%;
  transform: translate(-50%, -50%);*/
}

.fb-chat__bubbles {
  text-align: center;
}

.fb-chat__bubbles span {
  display: inline-block;
  background-color: #b6b5ba;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin-right: 5px;
  animation: bob 2s infinite;
}

.fb-chat__bubbles span:nth-child(1) {
  animation-delay: -1s;
}

.fb-chat__bubbles span:nth-child(2) {
  animation-delay: -0.85s;
}

.fb-chat__bubbles span:nth-child(3) {
  animation-delay: -0.7s;
  margin-right: 0;
}

@keyframes bob {
  10% {
    transform: translateY(-10px);
    background-color: #9e9da2;
  }

  50% {
    transform: translateY(0);
    background-color: #b6b5ba;
  }
}

.feather-small {
  width: 18px;
  height: 18px;
}
</style>
