import { render, staticRenderFns } from "./hallucinations-chain.vue?vue&type=template&id=19ff6804&scoped=true&"
import script from "./hallucinations-chain.vue?vue&type=script&lang=js&"
export * from "./hallucinations-chain.vue?vue&type=script&lang=js&"
import style0 from "./hallucinations-chain.vue?vue&type=style&index=0&id=19ff6804&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ff6804",
  null
  
)

export default component.exports